import { z } from "zod";
import type { Chain } from "../chain";
import type { Identity } from "../utils/identity";

export type Wallet = {
  id: string;
  address: string;
  chainId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type WalletWithChain = Identity<Wallet & { chain: Chain }>;

export const newWalletSchema = z.object({
  chainId: z.string().uuid(),
  address: z.string(),
});

export type NewWallet = z.infer<typeof newWalletSchema>;
