import { z } from "zod";

export type RewardSpecBudgetAdjustment = {
  id: string;
  rewardSpecId: string;
  userId: string;
  amount: number;
  reason: string | null;
  createdAt: Date;
};

export const rewardSpecBudgetAdjustmentSchema = z.object({
  id: z.string(),
  rewardSpecId: z.string().uuid(),
  userId: z.string().uuid(),
  amount: z.number(),
  reason: z.string().nullable(),
  createdAt: z.date(),
}) satisfies z.ZodType<RewardSpecBudgetAdjustment>;

export type NewRewardSpecBudgetAdjustment = Pick<RewardSpecBudgetAdjustment, "rewardSpecId" | "amount" | "reason">;

export const newRewardSpecBudgetAdjustmentSchema = z.object({
  rewardSpecId: z.string().uuid(),
  amount: z.number(),
  reason: z.string().nullable(),
}) satisfies z.ZodType<NewRewardSpecBudgetAdjustment>;
