import { z } from "zod";
import type { TokenWithAssociations } from "../token";
import { decimalSchema } from "../utils/zod";
import type { Requirement } from "./requirement";

const RewardTypeSchema = z.enum(["TOKEN_FIXED", "TOKEN_PERCENT", "FLOW_POINTS", "LOTTERY"]);
export type RewardType = z.infer<typeof RewardTypeSchema>;

export type RewardDistributionWorkflowResponse = {
  success: boolean;
  message: string;
};

export const rewardSpecSchema = z.object({
  id: z.string(),
  type: RewardTypeSchema,
  questId: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  scoreMin: z.number().nullable(),
  scoreMax: z.number().nullable(),
  tokenId: z.string().nullable(),
  tokenBudget: decimalSchema.nullable(),
  tokenSpent: decimalSchema.nullable(),
  tokenAllocated: decimalSchema.nullable(),
  tokenFixedAmount: decimalSchema.nullable(),
  tokenPercentMultiplier: z.number().nullable(),
  tokenPercentMaxAmount: decimalSchema.nullable(),
  tokenPercentRequirementId: z.string().nullable(),
  flowPointsBudget: z.number().nullable(),
  flowPointsAllocated: z.number().nullable(),
  flowPointsBoxesAmount: z.number().nullable(),
  flowPointsKeysAmount: z.number().nullable(),
});

export type RewardSpec = z.infer<typeof rewardSpecSchema>;

// This is a utility type that makes all fields in T required except for K.
type StrictRequired<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};

export type RewardSpecTokenFixed = StrictRequired<
  RewardSpec,
  "tokenId" | "tokenBudget" | "tokenSpent" | "tokenFixedAmount"
> & {
  type: "TOKEN_FIXED";
  token: TokenWithAssociations;
};

export type RewardSpecTokenPercent = StrictRequired<
  RewardSpec,
  | "tokenId"
  | "tokenBudget"
  | "tokenSpent"
  | "tokenPercentMultiplier"
  | "tokenPercentMaxAmount"
  | "tokenPercentRequirementId"
> & {
  type: "TOKEN_PERCENT";
  token: TokenWithAssociations;
};

export type RewardSpecFlowPoints = StrictRequired<
  RewardSpec,
  "flowPointsKeysAmount" | "flowPointsBoxesAmount" | "flowPointsBudget" | "flowPointsAllocated"
> & {
  type: "FLOW_POINTS";
};

export type RewardSpecLottery = RewardSpec & {
  type: "FLOW_POINTS";
};

// This is a discriminated union of all possible reward spec types. Allows us to easily switch on the type field.

export function isTokenFixed(rewardSpec: RewardSpec): rewardSpec is RewardSpecTokenFixed {
  return rewardSpec.type === "TOKEN_FIXED";
}

export function isTokenPercent(rewardSpec: RewardSpec): rewardSpec is RewardSpecTokenPercent {
  return rewardSpec.type === "TOKEN_PERCENT";
}

export function isFlowPoints(rewardSpec: RewardSpec): rewardSpec is RewardSpecFlowPoints {
  return rewardSpec.type === "FLOW_POINTS";
}

export function isLottery(rewardSpec: RewardSpec): rewardSpec is RewardSpecLottery {
  return rewardSpec.type === "LOTTERY";
}

/** Used to display reward specs in the UI. */
export type RewardSpecWithMeta = RewardSpec & {
  requirements: Requirement[];
};

export type RewardSpecWithToken = RewardSpec & {
  token: TokenWithAssociations;
};

export const RewardSpecRemoveInputSchema = z.object({
  rewardSpecId: z.string(),
});

// Input types

export type RewardSpecRemoveInput = z.infer<typeof RewardSpecRemoveInputSchema>;

const baseRewardSpecFields = {
  scoreMin: z.number().nullish(),
  scoreMax: z.number().nullish(),
  questId: z.string().uuid(),
} as const;

export const rewardSpecTokenFixedCreateSchema = z.object({
  type: z.literal("TOKEN_FIXED"),
  tokenId: z.string().uuid(),
  tokenBudget: decimalSchema,
  tokenFixedAmount: decimalSchema,
  ...baseRewardSpecFields,
});

export const rewardSpecTokenPercentCreateSchema = z.object({
  type: z.literal("TOKEN_PERCENT"),
  tokenId: z.string().uuid(),
  tokenBudget: decimalSchema,
  tokenPercentMultiplier: z.number().min(0),
  tokenPercentMaxAmount: decimalSchema,
  tokenPercentRequirementId: z.string().uuid(),
  ...baseRewardSpecFields,
});

export const rewardSpecFlowPointsCreateSchema = z.object({
  type: z.literal("FLOW_POINTS"),
  flowPointsKeysAmount: z.number().min(0),
  flowPointsBoxesAmount: z.number().min(0),
  flowPointsBudget: z.number().min(0),
  ...baseRewardSpecFields,
});

export const rewardSpecLotteryCreateSchema = z.object({
  type: z.literal("LOTTERY"),
  ...baseRewardSpecFields,
});

export type RewardSpecTokenFixedCreate = z.infer<typeof rewardSpecTokenFixedCreateSchema>;
export type RewardSpecTokenPercentCreate = z.infer<typeof rewardSpecTokenPercentCreateSchema>;
export type RewardSpecFlowPointsCreate = z.infer<typeof rewardSpecFlowPointsCreateSchema>;
export type RewardSpecLotteryCreate = z.infer<typeof rewardSpecLotteryCreateSchema>;

export function isScoreEligible(score: number, rewardSpec: RewardSpec) {
  if (rewardSpec.scoreMin === null && rewardSpec.scoreMax === null) {
    return true;
  }

  if (rewardSpec.scoreMin && score < rewardSpec.scoreMin) {
    return false;
  }
  if (rewardSpec.scoreMax && score > rewardSpec.scoreMax) {
    return false;
  }
  return true;
}
