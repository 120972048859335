import { z } from "zod";

export type Journey = {
  id: string;
  title: string;
  slug: string;
  isOnboarding: boolean;
  prereqJourneyId: string | null;
  partnerId: string;
  createdAt: Date;
  updatedAt: Date;
};

export type JourneyCreate = Pick<Journey, "title" | "slug" | "isOnboarding" | "partnerId"> & {
  prereqJourneyId?: string | null | undefined;
};

export type JourneyUpdate = Omit<JourneyCreate, "partnerId"> & { id: string };

export const journeyCreateSchema = z.object({
  title: z.string(),
  slug: z.string(),
  isOnboarding: z.boolean(),
  partnerId: z.string(),
  prereqJourneyId: z.string().nullable().optional(),
}) satisfies z.ZodType<JourneyCreate>;

export const journeyUpdateSchema = z.object({
  id: z.string(),
  title: z.string(),
  slug: z.string(),
  isOnboarding: z.boolean(),
  prereqJourneyId: z.string().nullable().optional(),
}) satisfies z.ZodType<JourneyUpdate>;

export type JourneyQuest = {
  id: string;
  sortOrder: number;
  questId: string;
  journeyId: string;
};

export type JourneyQuestCreate = {
  questId: string;
  journeyId: string;
  sortOrder: number;
};

export const journeyQuestCreateSchema = z.object({
  questId: z.string(),
  journeyId: z.string(),
  sortOrder: z.number(),
}) satisfies z.ZodType<JourneyQuestCreate>;

export type JourneyQuestUpdate = {
  id: string;
  sortOrder: number;
};

export const journeyQuestUpdateSchema = z.object({
  id: z.string(),
  sortOrder: z.number(),
}) satisfies z.ZodType<JourneyQuestUpdate>;
