import { z } from "zod";
import type { QuestWithPartner } from "./quest";
import type { RequirementResult } from "./requirement";
import type { RewardEarning } from "./reward-earning";
import type { Wallet, WalletWithChain } from "./wallet";

export const questEntryStatusSchema = z.enum(["STARTED", "COMPLETED", "CLAIMED", "INELIGIBLE"]);
export type QuestEntryStatus = z.infer<typeof questEntryStatusSchema>;

export type QuestEntry = {
  id: string;
  questId: string;
  userId: string | null;
  status: QuestEntryStatus;
  score: number | null;
  walletId: string;
  selectedAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

// This type is used by the client to display the quest in the history list.
export type QuestEntryWithMeta = QuestEntry & {
  quest: QuestWithPartner;
  results: RequirementResult[];
};

export type QuestEntryWithWallet = QuestEntry & {
  wallet: Wallet | null;
};

export type QuestEntryWithWalletAndChain = QuestEntry & {
  wallet: WalletWithChain | null;
};

// This type is used by the client to display the status of the entry including the results of the requirement.
// Think of this like a "report card" for the user's progress in the quest.
export type QuestEntryWithResults = QuestEntry & {
  wallet: Wallet | null;
  results: RequirementResult[];
  rewardEarnings: RewardEarning[];
};

export function isStatusTerminalSuccess(status?: QuestEntryStatus): boolean {
  if (!status) {
    return false;
  }

  return status === "COMPLETED" || status === "CLAIMED" || status === "INELIGIBLE";
}
